export enum EApiCallStateNames {
  TICKET_REPORTS = 'TICKET_REPORTS',
}

export enum EApiRequestPartKeys {
  SEARCH = 'SEARCH',
  PAGINATION = 'PAGINATION',
  FILTERS = 'FILTERS',
  SORTING = 'SORTING',
}

import { EApiCallStateNames } from '@enums';
import { IBitfApiCallState } from '@interfaces';
import { DefaultApiCallStateMapper } from './default-api-call-state.mapper';

import { BitfPFormItemConfigApiRequestPart } from '@common/libs/bitforce/core/api-call-state/bitf-p-api-call-state';

export const apiCallStateConfig = [
  {
    apiCallStateName: EApiCallStateNames.TICKET_REPORTS,

    requestParts: [new BitfPFormItemConfigApiRequestPart()],
    apiCallStateMapper: new DefaultApiCallStateMapper(),
  } as IBitfApiCallState,
];
